import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import {
  getQueryParamNames,
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import {
  H3,
  H5,
  IconBox,
  IconChat,
  IconCircleTick,
  IconLock,
  Page,
  PrimaryButton,
  Trustpilot,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import {
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validFilterParams,
  validUrlQueryParamsFromProps,
} from './SearchPage.shared';
import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';
import css from './SearchPage.module.css';
import { heroImage } from '../../config/configBranding';
import IntercomLauncher from '../../components/IntercomLauncher/IntercomLauncher';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isMobileModalOpen: false,
    };

    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Reset all filter query parameters
  resetAll() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = {
          ...urlQueryParams,
          ...prevState.currentQueryParams,
        };
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  // Reset all filter query parameters
  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      config,
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      routeConfiguration,
      searchInProgress,
      searchListingsError,
      searchParams,
      scrollingDisabled,
    } = this.props;

    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, searchParamsInURL, urlQueryParams } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      defaultFiltersConfig,
      false
    );

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    const availableFilters = [
      ...customPrimaryFilters,
      ...defaultFilters,
      ...customSecondaryFilters,
    ];

    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );

    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          isConflictingFilterActive={!!conflictingFilterActive}
          mode={mode}
          onSelect={this.handleSortBy}
          selectedFilters={selectedFilters}
          showAsPopup
          sort={validQueryParams[sortConfig.queryParamName]}
        />
      ) : null;
    };

    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        location={location}
        resetAll={this.resetAll}
        totalItems={totalItems}
      />
    );

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const toggleIntercom = () => {
      const i = Intercom || window.Intercom();

      if (i) i('show');
    };

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        description={description}
        schema={schema}
        scrollingDisabled={scrollingDisabled}
        title={title}
      >
        <TopbarContainer rootClassName={topbarClasses} currentSearchParams={urlQueryParams} />

        <div className={css.heroContainer}>
          <div className={css.heroFloatContainer}>
            <ul>
              <li>
                <IconCircleTick colour="#ffffff" />
                <FormattedMessage id="SearchPage.floatMessageOne" />
              </li>
              <li>
                <IconCircleTick colour="#ffffff" />
                <FormattedMessage id="SearchPage.floatMessageTwo" />
              </li>
              <li>
                <IconCircleTick colour="#ffffff" />
                <FormattedMessage id="SearchPage.floatMessageThree" />
              </li>
            </ul>
          </div>

          <div className={css.heroImageContainer}>
            <img
              alt="Buy and sell used bikes easily with Moose Marketplace"
              height="266"
              loading="eager"
              src={heroImage}
              width="400"
            />
          </div>

          <div className={css.heroContent}>
            <H3 as="h1">
              <FormattedMessage id="SearchPage.heading" />
            </H3>
            <p>
              <FormattedMessage id="SearchPage.tagline" />
            </p>

            <div className={css.heroTrustpilot}>
              <Trustpilot />
            </div>
          </div>
        </div>

        {/* Content */}
        <div className={css.layoutWrapperContainer}>
          <aside className={css.layoutWrapperFilterColumn} data-testid="filterColumnAside">
            <div className={css.filterColumnContent}>
              {availableFilters.map(config => {
                return (
                  <FilterComponent
                    className={css.filter}
                    config={config}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    idPrefix="SearchFiltersMobile"
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    intl={intl}
                    isDesktop
                    key={`SearchFiltersMobile.${config.scope || 'built-in'}.${config.key}`}
                    liveEdit
                    marketplaceCurrency={marketplaceCurrency}
                    showAsPopup={false}
                    urlQueryParams={urlQueryParams}
                  />
                );
              })}

              <button className={css.resetAllButton} onClick={e => this.handleResetAll(e)}>
                <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
              </button>
            </div>
          </aside>

          <div className={css.layoutWrapperMain} role="main">
            <div className={css.searchResultContainer}>
              <SearchFiltersMobile
                className={css.searchFiltersMobileList}
                isMapVariant={false}
                listingsAreLoaded={listingsAreLoaded}
                noResultsInfo={noResultsInfo}
                onCloseModal={this.onCloseMobileModal}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                resetAll={this.resetAll}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                selectedFiltersCount={selectedFiltersCountForMobile}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                sortByComponent={sortBy('mobile')}
                urlQueryParams={validQueryParams}
              >
                {availableFilters.map(config => {
                  return (
                    <FilterComponent
                      config={config}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      idPrefix="SearchFiltersMobile"
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      intl={intl}
                      key={`SearchFiltersMobile.${config.scope || 'built-in'}.${config.key}`}
                      liveEdit
                      marketplaceCurrency={marketplaceCurrency}
                      showAsPopup={false}
                      urlQueryParams={validQueryParams}
                    />
                  );
                })}
              </SearchFiltersMobile>

              <MainPanelHeader
                className={css.mainPanel}
                isSortByActive={sortConfig.active}
                listingsAreLoaded={listingsAreLoaded}
                noResultsInfo={noResultsInfo}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                sortByComponent={sortBy('desktop')}
              />

              <div
                className={classNames(css.listingsForGridVariant, {
                  [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                })}
              >
                {searchListingsError ? (
                  <H3 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </H3>
                ) : null}

                {!isValidDatesFilter ? (
                  <H5>
                    <FormattedMessage id="SearchPage.invalidDatesFilter" />
                  </H5>
                ) : null}

                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  isMapVariant={false}
                  listings={listings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Callout content */}
        <div className={css.calloutContainer}>
          <ul>
            <li>
              <IconChat colour="#f04f36" />
              <div className={css.calloutText}>
                <b>
                  <FormattedMessage id="SearchPage.callOutOneHeading" />
                </b>
                <p>
                  {intl.formatMessage({ id: 'SearchPage.callOutOneTextPrefix' })}{' '}
                  <a href="tel:01225637264">01225 637264</a>
                  {intl.formatMessage({ id: 'SearchPage.callOutOneTextSuffix' })}
                </p>
              </div>
            </li>

            <li>
              <IconLock colour="#f04f36" />
              <div className={css.calloutText}>
                <b>
                  <FormattedMessage id="SearchPage.callOutTwoHeading" />
                </b>
                <p>
                  <FormattedMessage id="SearchPage.callOutTwoText" />
                </p>
              </div>
            </li>

            <li>
              <IconBox colour="#f04f36" />
              <div className={css.calloutText}>
                <b>
                  <FormattedMessage id="SearchPage.callOutThreeHeading" />
                </b>
                <p>
                  <FormattedMessage id="SearchPage.callOutThreeText" />
                </p>
              </div>
            </li>
          </ul>
        </div>

        <FooterContainer />

        <IntercomLauncher />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
};

SearchPageComponent.propTypes = {
  config: object.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  listings: array,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  routeConfiguration: arrayOf(propTypes.route).isRequired,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      history={history}
      intl={intl}
      location={location}
      routeConfiguration={routeConfiguration}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
  } = state.SearchPage;

  const listings = getListingsById(state, currentPageResultIds);
  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
